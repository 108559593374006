import { defineAsyncComponent } from "vue"

import { rinnai } from "@/main"
import Vue3Toasity from 'vue3-toastify';
import { toast } from 'vue3-toastify';

import { useRouter } from "./jobs-dashboard/use-router"

let quickOrder = defineAsyncComponent( () => import( "./quick-order/index.vue" ) )
let fireDesigner = defineAsyncComponent( () => import( "./fire-designer/index.vue" ) )
let jobsDashboard = defineAsyncComponent( () => import( "./jobs-dashboard/index.vue" ) )
let heatPumpCalculator = defineAsyncComponent( () => import( "./heat-pump-calculator/index.vue" ) )
let productSearchSelect = defineAsyncComponent( () => import( "./product-search-select/index.vue" ) )

let cartApiComponent = defineAsyncComponent( () => import( "./cart/index.vue" ) )

let modal = defineAsyncComponent( () => import( "./modal/index.vue" ) )

let fancyInput = defineAsyncComponent( () => import( "./fancy-input/index.vue" ) )

export let useComponents = (app, data) => {
    
    app.use( Vue3Toasity, {
        closeButton: false,
        position: toast.POSITION.BOTTOM_RIGHT,
    } )

    app.config.globalProperties.rinnai = rinnai;

    app.component( 'heat-pump-calculator', heatPumpCalculator )
    app.component( 'fire-designer', fireDesigner )
    app.component( 'quick-order', quickOrder )
    app.component( 'jobs-dashboard', jobsDashboard )
    app.component( 'product-search-select', productSearchSelect)
    app.component( 'fancy-modal', modal )
    app.component( 'fancy-input', fancyInput )

    app.component( 'rinnai-cart-api', cartApiComponent )
    /**
        vue-router is stupid and stubborn and insists on being injected onto app.use.
        therefor this ugliness.
    */
    
    if( data.appID === 'jobs-dashboard' ) {
        app.use( useRouter() ) 
    }

}