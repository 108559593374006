// @ts-nocheck

let tag:any = document.querySelector('meta[name="rinnai-vue"]') ?? { content: "" }
__webpack_public_path__ = tag.content

import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

import { create } from "./custom-element"
import { useCrud } from "./use-crud"
import { useComponents } from "./components"
import { useCartApi } from "./components/cart/api.ts" 

export let rinnai = {};

window.rinnai = rinnai

/**
    Setup
*/

rinnai.crud = useCrud( rinnaiData )

rinnai.toast = toast

rinnai.toast.alert = (status, messages ) => {
    if( status === true ) {
        rinnai.toast.success( messages.success )
    }
    if( status === false ) {
        rinnai.toast.error( messages.error )
    }
}

useCartApi()

rinnai.formatCurrency = ( amount, locale = 'en-US', currency = 'USD' ) => new Intl.NumberFormat( locale, {
    style: 'currency',
    currency: currency,
} ).format( amount )



create( 'rinnai-app', ( app, data ) => useComponents( app, data ) )
