<template>
    <button 
        class="btn btn-primary px-3 py-2 text-white rounded-0" 
        @click="modalOpen = true"
        v-bind="$attrs"
    >
        NEW JOB
    </button>        
    <fancy-modal 
        v-model:open='modalOpen'
    >
        <div class="p-4 bg-white my-auto col-12" style="max-width: 560px">
            <div class="d-flex">
                <i 
                    class="fa fa-times ms-auto" 
                    role="button" 
                    @click="modalOpen =false"
                />
            </div>
            <h3 class="text-center">New Job</h3>
            <div class="grid columns-1 gap-2">
                <label class="grid columns-1 gap-1">
                    <span>Name</span>
                    <input 
                        v-model='jobTitle' 
                        class='form-control rounded-0'
                        placeholder='Job Name Jere'
                    >
                </label>
                <label class="grid columns-1 gap-1">
                    <span>Description</span>
                    <textarea 
                        v-model='jobDescription' 
                        class='form-control rounded-0'
                        placeholder='Information to describe job'
                        rows="5"
                    />
                </label>
                <button 
                    @click='addJob'
                    class='btn btn-orange-400 text-white rounded-0 p-2 px-4 col-12'
                    :disabled='jobTitle==""'
                >
                    CREATE JOB
                </button>
            </div>
        </div>
    </fancy-modal>
</template>
<script setup>

defineOptions({
    inheritAttrs: false
})

import { ref } from "vue"

import { rinnai } from "@/main.ts"; 
import { context } from "../context"

let dashboard = context.inject.dashboard()

let jobTitle = ref('')
let jobDescription = ref('')

let modalOpen = ref( false )

let addJob = async() => {
    modalOpen.value = false
    await dashboard.addJob( { title: jobTitle.value, description: jobDescription.value } )
    jobTitle.value = ''
    jobDescription.value = ''
}

</script>
<style scoped lang='scss'>

</style>