
import main from "./main/index.vue"
import job from "./job/index.vue"
import notFound from "./404/index.vue"

export let routes = [
    { 
        path: '/', 
        component: main,
        props: true 
    },
    { 
        path: '/job/:jobID', 
        component: job,
        props: true
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: notFound,
        props: true,
    },
]
