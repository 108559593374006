import { createWebHistory, createRouter } from "vue-router"
import { routes } from "./routes"

export let useRouter = () => {
    
    const router = createRouter( {
        history: createWebHistory( '/my-jobs/' ),
        routes,
    } )

    return router

}