// @ts-nocheck
import { InjectionKey, ShallowRef, provide } from "vue"
import { injectStrict } from "@/utils/inject-strict"

let dashboard: InjectionKey< any > = Symbol('jobs-dashboard')

export let context = {
    inject: {
        dashboard: () => injectStrict( dashboard )
    },
    provide: {
        dashboard: (...args) =>  provide( dashboard, ...args )
    }
}
