<template>
    <div class="d-flex gap-1 align-items-center">
        <span class="ms-auto">VIEW BY:</span>
        <button
            class="btn text-white p-1 px-2"
            :class="{ 'btn-primary': filter=='all', 'btn-db-grey-800': filter!='all'}"
            @click="filter = 'all'"
        >ALL ITEMS</button>
        <button
            class="btn text-white p-1 px-2"
            :class="{ 'btn-primary': filter=='ordered', 'btn-db-grey-800': filter!='ordered'}"
            @click="filter = 'ordered'"
        >ORDERED ITEMS</button>
        <button
            class="btn text-white p-1 px-2"
            :class="{ 'btn-primary': filter=='to-order', 'btn-db-grey-800': filter!='to-order'}"
            @click="filter = 'to-order'"
        >ITEMS TO ORDER</button>
    </div>
</template>
<script setup>
let filter = defineModel( "filter" )
</script>