
// @ts-nocheck

import { rinnai } from "@/main"

import { reactive, watch } from "vue"
 
import { useDebounceFn } from '@vueuse/core'

interface addProduct {
    
    addItem: {
        
        quantity: number 

        product_id: number
        variation_id: number
        
        /**
            Additional meta data to attach to item in cart
        */
        cart_item_data?: {}

        /**
            Additional variation data to attach to item in cart
        */
        variation?: {}

    }

}

let updateQuantity = useDebounceFn( async( hash: string, quantity: number  ) => {
  
  api.busy = true

    let res = rinnai.crud("woo/cart").init({}).update({
            itemQuantity: {
                quantity, 
                hash
            },
        },
        false
    )
    
    api.hash++

    await res

    api.busy = false

    return res
    
}, 500 )

export let api = reactive( {

    /**
        Load state injected via localize_script. 
    */    
    ...rinnaiData?.woo_cart?.result ?? {},

    /**
        Hash is an incremented value that other components can watch to do things
        when the cart is updated. 
    */
    hash: 0,
    open: false,
    busy: false,

    show: ( timeout = 3000 ) => {
        
        api.open = true

        setTimeout( () => api.open = false, timeout )

    },

    hide: () => api.open = false,
    
    toggleOpen: () => api.open = !api.open,

    addItem: async ( product: addProduct ) => {
        
        api.busy = true

        let res = rinnai.crud("woo/cart").init({}).update(
            product,
            false
        )
        
        api.hash++

        await res

        api.busy = false

        return res

    },
    
    removeItem: async ( removeItem: string ) => {
        
        api.busy = true
        
        let res = rinnai.crud("woo/cart").init({}).update(
            {
                removeItem 
            },
            false
        )
        
        api.hash++

        await res;

        api.busy = false

        return res

    },
    updateQuantity,

} )

export let useCartApi = () => {

    rinnai.cart = api

    watch( () => api.hash, async () => {
        
        api.busy = true 
        
        /**
            Uses data passed via localize_script to control the data
            fetched via CRUD to sync cart data.
        */
        let res = await rinnai.crud('woo/cart').init().read( rinnaiData.woo_cart.read )

        Object.assign( api, res )

        api.busy = false 

    } )

}