<template>
    <div class='grid columns-1 gap-2'>
        <div class="d-flex gap-4 align-items-center">
            <span class="fs-36 fw-8 lh-20">My Jobs</span>
            <span>{{ dashboard.openJobs.length }} Open Jobs</span>
            <add-job class="ms-auto"/>
        </div>
        <job-list />
    </div>
</template>
<script setup>

import { context } from "../context"
import addJob from "./add-job"
import jobList from "./job-list"

let dashboard = context.inject.dashboard()

</script>
