<template>
<div class="jobs-list">
    <div class='entry-row bg-db-grey-800 text-white'>
        <span class='column'>ID</span>
        <span class='column'>Job Title</span>
        <span class='column'>Status</span>
        <span class='column'>Created by</span>
        <span class='column'>Date Created</span>
        <span class='column'>Actions</span>
    </div>
    <div 
        v-if='dashboard.jobs.length == 0'
        class='p-4  bg-gray-100'
    >
        {{ dashboard.busy ? "Loading results" : "No Jobs found." }}
    </div>
    <div v-for='job in dashboard.jobs' class='entry-row job-entry'> 
        <span class='column'>#{{ job.ID }}</span>
        <span class='column'>{{ job.title }}</span>
        <span class='column'>
            <span 
                class="px-2 py-1 text-capitalize"
                :class="{ 'bg-db-green-400 text-white': job.status == 'open' }"
            >
                    {{ job.status }}
                </span>
        </span>
        <span class="column">{{ job.createdBy.name }}</span>
        <span class="column"> {{  job.createdDate  }}</span>
        <div class="d-flex column align-items-center gap-2">
            <router-link :to="`/job/${job.ID}`" class='fa fa-edit text-db-grey-200'/>
            <button class='btn rounded-0 p-1'
                @click='dashboard.removeJob( job.ID )'
            >
                <i 
                    class='fa fa-trash btn-db-grey-200 ' 
                    role='button'
                    
                />
            </button>
        </div>
    </div>
</div>
</template>
<script setup>
import { context } from "../context"

let dashboard = context.inject.dashboard()
 
</script>
<style scoped lang='scss'>
.entry-row {
    display: grid;
    grid-template-columns: auto 4fr 1fr 1fr 1fr 160px;
    gap: 10px;
    align-items: center;
}
.column {
    padding: 20px;
}
.job-entry {
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #DFDFDF;
    background: #fff;;
    &:nth-of-type( odd )  {
        background: var(--bs-db-grey-100);
    }

}

.jobs-list {
    border-width: 1px;
    border-style: solid;
    border-color: #DFDFDF;
    .entry-row {
        transition: .5s background;
        &:nth-of-type( even ){
            background: var(--bs-white);
        }
        &:nth-of-type( odd ){
            background: var(--bs-gray-100);
        }
        &:hover {
            background: var(--bs-gray-200);
        }
    }
}
</style>